import { processingMessageSubject } from "./UI";

export const ENCODING_API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3337"
    : `${window.location.protocol}//${window.location.host}`;
export const DASHBOARD_API_URL = `https://dev.pocketpilot.jura.com`;

export let token = ""; // the Dashbaord token (not Keycloak)
export let companies: CompanyDetails[] = [];

export interface CompanyDetails {
  id: string;
  name: string;
  ownerId: string;
  createdAt: string;
  updatedAt: string;
  defaultPaymentId: string;
  parentCompanyId: string;
  currency: string;
  role: string;
  credit: number;
  creditTopUps: CreditTopUpResBody[];
}
export interface CreditTopUpResBody {
  id: string;
  companyId: string;
  value: number;
  createdAt: string;
  updatedAt: string | null;
}

export interface LocationDetails {
  name: string;
  id: string;
}

export interface UserDetails {
  active: boolean;
  address: string;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  isKeycloakUser: string;
  lastName: string;
  preferredLanguage: string;
  token: string;
  tutorialCompleted: boolean;
  updatedAt: string;
}

// Coffee Machine Setup Body for submitting
export interface SetupBody {
  xml: string;
  serial: string;
  name: string;
  protocolVersion: string;
  frogVersion: string;
  bluetoothPin: string;
  pin: string; // the setup pin (p-mode pin)
  maintenancePin: string;
  location: string;
}

// Dashboard endpoints ---------------------------------------------------------------

export const exchangeToken = async (keycloakToken: string): Promise<string> => {
  // try {
  //   const fetchRes = await fetch(`${DASHBOARD_API_URL}/api/user/oauth2/keycloak/exchangeToken`, {
  //     body: JSON.stringify({ token: keycloakToken }),
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //   });
  //   const resJSON = await fetchRes.json();
  //   console.log("received exchanged token json:", resJSON);
  //   token = resJSON.token; // set the token here too
  //   return resJSON.token;
  // } catch (e) {
  //   console.error("couldn't exchange token with novu", e);
  // }
  // return "";

  try {
    const postBody = { keycloakToken: keycloakToken };
    const fetchRes = await fetch(`${ENCODING_API_URL}/exchangeToken`, {
      body: JSON.stringify(postBody),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const resJson = await fetchRes.json();
    // console.log("received decrypted json res:", resJson);
    token = resJson.token;
    return resJson.token;
  } catch (e) {
    console.error("failed to get the decrypted message", e);
  }
  return "";
};

export async function authenticatedPost(path, data) {
  // const result = await fetch(`${DASHBOARD_API_URL}${path}`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  //   body: JSON.stringify(data),
  // });

  // if (!result.ok) {
  //   throw new Error(await result.text());
  // }

  // return result.json();

  try {
    let postBody = { clientPath: path, token: token };
    const fetchRes = await fetch(`${ENCODING_API_URL}/authenticatedPost`, {
      body: JSON.stringify({ ...postBody, ...data }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const resJson = await fetchRes.json();
    // console.log("received decrypted json res:", resJson);
    return resJson;
  } catch (e) {
    console.error("failed to get authenticated Post", e);
  }
  return "";
}

export async function authenticatedGet(path) {
  // const result = await fetch(`${DASHBOARD_API_URL}${path}`, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // });

  // if (!result.ok) {
  //   throw new Error(await result.text());
  // }

  // return result.json();

  try {
    const fetchRes = await fetch(
      `${ENCODING_API_URL}/authenticatedGet?clientPath=${encodeURIComponent(
        path
      )}&token=${encodeURIComponent(token)}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const fetchJson = await fetchRes.json();
    return fetchJson;
  } catch (e) {
    console.error("couldnt' get the authenticated get", e);
  }
  return undefined;
}

export async function getCommand(command, params) {
  const commandResponse = await authenticatedPost(`/api/command/${command}`, {
    ...params,
    encryption: "none",
  });
  console.log(commandResponse);
  return commandResponse;
}

export async function createCoffeeMachineFlow(
  cmSerial: string,
  companayID: string,
  locationID: string,
  cmName: string,
  maintenancePin: string,
  pmodePin: string,
  xml: string // article number
) {
  console.log("---- createCoffeeMachineFlow ----");

  // Create the coffee machine
  let coffeeMachine = undefined;
  try {
    coffeeMachine = await authenticatedPost(`/api/companies/${companayID}/coffee-machines`, {
      name: cmName + "_" + cmSerial,
      location: locationID,
      frogVersion: 2.19,
      maintenancePin: maintenancePin,
      protocolVersion: 1,
      xml: xml,
      serial: cmSerial,
      pin: pmodePin,
      bluetoothPin: "000000",
    });
    console.log(coffeeMachine);
  } catch (e) {
    console.error("failed to create coffee machine:", e);
    processingMessageSubject.next(`Failed to create coffee machine: ${e}`);
  }

  console.log("---- end createCoffeeMachineFlow ----");

  return coffeeMachine;
}

export async function initializeFrogFlow(cmID: string, frogId: string, companyID: string) {
  console.log("---- initializeFrogFlow ----");

  // Initialize the frog
  let initInfo = undefined;
  try {
    initInfo = await authenticatedPost(
      `/api/companies/${companyID}/coffee-machines/${cmID}/initialize-frog`,
      {
        frogId,
      }
    );
    console.log(initInfo);
  } catch (e) {
    console.error("Failed to initialize the coffee machine:", e);
    processingMessageSubject.next(`Failed to initialize coffee machine on dasbhoard: ${e}`);
  }

  console.log("---- end initializeFrogFlow ----");

  return initInfo;
}

export const setAvailableCompanies = async (): Promise<boolean> => {
  try {
    const companiesJson = await authenticatedGet(`/api/user/companies`);
    console.log("received companies", companiesJson);
    companies = companiesJson;
    return true;
  } catch (e) {
    console.error("failed to get the companies", e);
  }
  return false;
};

export const getAvailableLocations = async (companyId: string): Promise<LocationDetails[]> => {
  try {
    const locationsJson = await authenticatedGet(`/api/companies/${companyId}/locations`);
    console.log("received locations", locationsJson);
    return locationsJson;
  } catch (e) {
    console.error("couldnt' get the locations for company id:", companyId);
  }

  return [];
};

// My backend endpoints ------------------------------------------------

/**
 *
 * @param encryptedHex
 * @returns hex string representation of the decrypted bytes
 */
export const getDecryptedMessage = async (
  encryptedHex: string,
  aesKey: string
): Promise<string> => {
  try {
    const fetchRes = await fetch(`${ENCODING_API_URL}/getDecryptedMessage`, {
      body: JSON.stringify({ aesKey: aesKey, frogMessage: encryptedHex }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const resJson = await fetchRes.json();
    // console.log("received decrypted json res:", resJson);
    return resJson.decryptedHex;
  } catch (e) {
    console.error("failed to get the decrypted message", e);
  }

  return "";
};

/**
 * @param messageStr Should be the ascii string of the message to be encrypted (the human readable command)
 * @returns Hex string representation of the encrypted bytes
 */
export const getEncryptedMessage = async (messageStr: string, aesKey: string): Promise<string> => {
  try {
    const fetchRes = await fetch(`${ENCODING_API_URL}/getEncryptedMessage`, {
      body: JSON.stringify({
        aesKey: aesKey,
        message: messageStr,
      }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const resJson = await fetchRes.json();
    // console.log("received encryption json", resJson);
    return resJson.encryptedFrogMessageHex;
  } catch (e) {
    console.error("couldnpt get encryption", e);
  }

  return "";
};

// In case needed somewhere, here for reference
export const getBase64FromHex = async (hex: string): Promise<string> => {
  try {
    const fetchRes = await fetch(`${ENCODING_API_URL}/getBase64FromHex`, {
      body: JSON.stringify({ hex: hex }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const resJson = await fetchRes.json();
    return resJson.base64;
  } catch (e) {
    console.error("failed to get base64", e);
  }
  return "";
};

/**
 * Shared Secret for new Bluetooth session.
 * @param publicKeyHex
 * @returns
 */
export const createAndGetSharedSecret = async (
  publicKeyHex: string
): Promise<{ sharedSecretHex: string; myPublicKeyHex: string } | undefined> => {
  try {
    const fetchRes = await fetch(`${ENCODING_API_URL}/createSharedSecret`, {
      method: "POST",
      body: JSON.stringify({
        publicKey: publicKeyHex,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const resJson = await fetchRes.json();
    console.log("received sharedSecret json", resJson);
    return {
      sharedSecretHex: resJson.sharedSecret,
      myPublicKeyHex: resJson.serverPublicKey,
    };
  } catch (e) {
    console.error("couldn't create shared secret", e);
  }

  return undefined;
};

/**
 * Gets the prepared certificate with private key from my server. It's ready to be sent as a
 * payload for the frog. Certificate shouldn't be made through this anymore, but through dashboard.
 * @param frogUid
 * @returns
 */
export const getEncryptedCert = async (
  frogUid: string
): Promise<{ encryptedCertB64: string; encryptedPrivKeyB64: string } | undefined> => {
  try {
    const fetchRes = await fetch(
      // "https://164.90.177.79:3337/setupApp/certificate",
      // "https://jppv3-prototype.jura-contactless.com/setupApp/certificate",
      "https://tmp0.jura-contactless.com/setupApp/certificate",
      {
        method: "POST",
        body: JSON.stringify({ FrogUID: frogUid }),
        headers: { "Content-Type": "application/json" },
      }
    );
    const fetchJson = await fetchRes.json();
    return {
      encryptedCertB64: fetchJson.EncryptedCertificateBase64 ?? "",
      encryptedPrivKeyB64: fetchJson.EncryptedKeyBase64 ?? "",
    };
  } catch (e) {
    console.error("couldn't get the certificate from the server");
    return undefined;
  }
};

export const getSetupInfo = async (): Promise<any> => {
  try {
    const fetchRes = await fetch(`${ENCODING_API_URL}/getSetupInfo`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const fetchJson = await fetchRes.json();
    let setupinfo = fetchJson.message;
    setupinfo = atob(setupinfo);
    setupinfo = JSON.parse(setupinfo);
    // console.log("setupinfo", setupinfo);
    return setupinfo;
  } catch (e) {}
  return undefined;
};

export const getConnectInfo = async (): Promise<any> => {
  try {
    const fetchRes = await fetch(`${ENCODING_API_URL}/getConnectInfo`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const fetchJson = await fetchRes.json();
    let setupinfo = fetchJson.message;
    setupinfo = atob(setupinfo);
    setupinfo = JSON.parse(setupinfo);
    // console.log("connectInfo", setupinfo);
    return setupinfo;
  } catch (e) {}
  return undefined;
};

// utils regarding data from the backend ------------------------------------------------------------

export const getIsMachineManager = (company: CompanyDetails): boolean => {
  return (
    company.role === "admin" ||
    company.role === "product_manager" ||
    company.role === "coffee_machine_owner"
  );
};
