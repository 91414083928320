import { Subject } from "rxjs";
import {
  createAndGetSharedSecret,
  createCoffeeMachineFlow,
  initializeFrogFlow,
  getCommand,
  getEncryptedMessage,
  getDecryptedMessage,
  getSetupInfo,
  getConnectInfo,
} from "./backend";
import { processingMessageSubject } from "./UI";
import { isIOS } from "react-device-detect";

export let theBLEDevice: BluetoothDevice;
let theAbf3: BluetoothRemoteGATTCharacteristic;
let theAbf5: BluetoothRemoteGATTCharacteristic;
let theAbf6: BluetoothRemoteGATTCharacteristic;

const abf4Subject: Subject<string> = new Subject();

let frogUid = ""; // temporarily store the frog uid

/**
 * Function for connecting to the frog via BLE, before executing the setup commands.
 * @returns The shared secret for the bluetooth session.
 */
export const connect = async () => {
  let device: BluetoothDevice;
  try {
    if (!isIOS) {
      let namePrefix = getFrogNameFromUrl();
      if (!namePrefix) namePrefix = "Jura";
      //console.log("namePrefix", namePrefix);
      device = await window.navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: namePrefix }],
        acceptAllDevices: false,
        optionalServices: ["0000abf0-0000-1000-8000-00805f9b34fb"],
      });
    } else {
      device = await window.navigator.bluetooth.requestDevice({
        filters: [{ name: getFrogNameFromUrl() }],
        acceptAllDevices: false,
        optionalServices: ["0000abf0-0000-1000-8000-00805f9b34fb"],
      });
      (device as any).name = getFrogNameFromUrl();
    }
  } catch (e) {
    console.error("Failed to connect");
    return;
  }

  let gattServer: BluetoothRemoteGATTServer;
  try {
    gattServer = await device.gatt.connect();
  } catch (e) {
    console.error("failed to connect to GATT server");
    return;
  }

  device.addEventListener("gattserverdisconnected", (e) => {
    //console.warn("device disconnected");
  });

  let primaryService: BluetoothRemoteGATTService;
  try {
    primaryService = await gattServer.getPrimaryService("0000abf0-0000-1000-8000-00805f9b34fb");
  } catch (e) {
    console.error("couldn't get primary service");
    return;
  }

  let abf3Characteristic: BluetoothRemoteGATTCharacteristic;
  try {
    abf3Characteristic = await primaryService.getCharacteristic(
      "0000abf3-0000-1000-8000-00805f9b34fb"
    );
  } catch (e) {
    console.error("couldn't get abf3 charectiristic");
    return;
  }
  let abf4Characteristic: BluetoothRemoteGATTCharacteristic;
  try {
    abf4Characteristic = await primaryService.getCharacteristic(
      "0000abf4-0000-1000-8000-00805f9b34fb"
    );
  } catch (e) {
    console.error("couldn't get abf4 charectiristic");
    return;
  }
  let abf5Characteristic: BluetoothRemoteGATTCharacteristic;
  try {
    abf5Characteristic = await primaryService.getCharacteristic(
      "0000abf5-0000-1000-8000-00805f9b34fb"
    );
  } catch (e) {
    console.error("couldn't get abf5 charectiristic");
    return;
  }
  let abf6Characteristic: BluetoothRemoteGATTCharacteristic;
  try {
    abf6Characteristic = await primaryService.getCharacteristic(
      "0000abf6-0000-1000-8000-00805f9b34fb"
    );
  } catch (e) {
    console.error("couldn't get abf6 charectiristic");
    return;
  }

  // handle notifications
  const handleNotificationsAbf4 = (event: Event) => {
    let value = (event.target as BluetoothRemoteGATTCharacteristic).value;
    //console.log("received abf4 notification:");
    //console.log(value);
    const hexArr: string[] = [];
    for (let i = 0; i < value.byteLength; i++) {
      let hexVal = value.getUint8(i).toString(16);
      if (hexVal.length < 2) hexVal = `0${hexVal}`;
      hexArr.push(hexVal);
    }

    //console.log("hex string of abf4 notif:", hexArr.join(""));
    abf4Subject.next(hexArr.join(""));
  };
  abf4Characteristic.addEventListener("characteristicvaluechanged", handleNotificationsAbf4);

  try {
    await abf4Characteristic.startNotifications();
  } catch (e) {
    console.error("failed to start abf4 notification listen");
    return;
  }

  // write possible now
  theBLEDevice = device;
  theAbf3 = abf3Characteristic;
  theAbf5 = abf5Characteristic;
  theAbf6 = abf6Characteristic;

  await new Promise((resolve) => setTimeout(resolve, 200));
  // await _writeProcessRef();
  //console.log(`should've connected now to ${theBLEDevice?.name}`);

  if (!theBLEDevice?.gatt?.connect) {
    console.error("attemtped to write to disconnected device");
    return;
  }

  // ecdh key exchange, using my express server's commands

  const frogPubKey = await readBleCharacteristic(theAbf6, 4);
  if (!frogPubKey) {
    //console.warn("abortign because of missing frogPubKey");
    return;
  }

  const sharedSecret: {
    sharedSecretHex: string;
    myPublicKeyHex: string;
  } = await createAndGetSharedSecret(frogPubKey);
  if (!sharedSecret) {
    //console.warn("aborting because of missing sharedSecret");
    return;
  }

  const sendFrogPubKeySucc = await writeBleCharacteristic(
    "",
    theAbf5,
    `${(sharedSecret.myPublicKeyHex.length / 2).toString(16)}${sharedSecret.myPublicKeyHex}`
  );
  if (!sendFrogPubKeySucc) {
    //console.warn("aborting because sendfrogpubkey failed");
    return;
  }

  // end of ecdh key exchange

  const preSetupConnectCommands = await getConnectInfo();
  // 'log in' to frog and set timeouts
  for (const cmd of preSetupConnectCommands) {
    const succ = await writeBleCharacteristic(
      sharedSecret.sharedSecretHex,
      theAbf3,
      cmd.cmd,
      cmd.expectedReply,
      ""
    );
    if (!succ) {
      return;
    }
  }

  // await handleSetup(sharedSecret);

  return sharedSecret;
};

/**
 * Performs all steps to onbaord a new machine:
 * Creates cm in the backend, sends commands to the frog.
 * @param sharedSecret
 * @param companyID
 * @param locationID
 * @returns
 */
export const handleSetup = async (
  sharedSecretHex: string,
  companyID: string,
  locationID: string,
  ssid: string,
  wifiPW: string,
  pmodePin: string,
  maintenancePin: string,
  cmName: string
): Promise<boolean> => {
  // my frog during testing: C44F3315F839

  const setupCommands = await getSetupInfo();

  const frogUIDSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    setupCommands.FrogUID.cmd,
    setupCommands.FrogUID.expectedReplyPre,
    "FrogUID",

    true
  );
  if (!frogUIDSucc) return false;

  const frogInfo = getFrogInfo();
  if (!frogInfo)
    console.warn("couldn't get frog info from name, using default values for Giga W10");

  const cm = await createCoffeeMachineFlow(
    frogInfo ? frogInfo.serial : "no_name",
    companyID,
    locationID,
    cmName,
    maintenancePin,
    pmodePin,
    frogInfo ? frogInfo.articleNumber : "15549"
  );
  if (!cm) {
    console.error("setup failed, couldn't create cm on backend");
    return false;
  }
  //console.log("the frogUID", frogUid);
  if (!frogUid) {
    console.error("couldn't get frogUID for some reason!");
    return;
  }
  const frogInit = await initializeFrogFlow(cm.id, frogUid, companyID);
  if (!frogInit) {
    console.error("setup failed, couldn't initialize cm on backend");
    return false;
  }

  //console.log("end of test", frogInit); // certificate, privateKey
  const privKeyCmds: string[] = await getCommand("setPrivateKey", {
    privateKey: frogInit.privateKey,
  });
  // return;

  const routerSSIDSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    // setupCommands.RouterSSID.cmdPre + "DIP_Router",
    setupCommands.RouterSSID.cmdPre + ssid,
    setupCommands.RouterSSID.expectedReply
  );
  if (!routerSSIDSucc) return false;

  const routerPassSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    // setupCommands.RouterPass.cmdPre + "41340486",
    setupCommands.RouterPass.cmdPre + wifiPW,
    setupCommands.RouterPass.expectedReply
  );
  if (!routerPassSucc) return false;

  const cmNameSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    // setupCommands.RouterPass.cmdPre + "41340486",
    setupCommands.CMName.cmdPre + cmName,
    setupCommands.CMName.expectedReply
  );
  if (!cmNameSucc) return false;

  const serverURLSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    // setupCommands.ServerURL.cmdPre + "websocket.jura-pocket-pilot-backend.jkweb.dev",
    // setupCommands.ServerURL.cmdPre + "tmp0.jura-contactless.com",
    setupCommands.ServerURL.cmdPre + "jppv3-prototype.jura-contactless.com",
    // setupCommands.ServerURL.cmdPre + "164.90.177.79",
    setupCommands.ServerURL.expectedReply
  );
  if (!serverURLSucc) return false;

  const serverPortSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    setupCommands.ServerPort.cmdPre + "443",
    // setupCommands.ServerPort.cmdPre + "3337",
    setupCommands.ServerPort.expectedReply
  );
  if (!serverPortSucc) return false;

  // get certificate from server
  // let encryptedCert = await getEncryptedCert(frogUid);
  let encryptedCert = {
    encryptedPrivKeyB64: frogInit.privateKey,
    encryptedCertB64: frogInit.certificate,
  };
  if (!encryptedCert) return false;
  //console.log("encryptedcert", encryptedCert);
  // return;

  //console.log("encryptedPrivKey", encryptedCert);
  const privKeySucc = await writeSplitSetupCmdPackages(
    setupCommands.ServPrivKey,
    encryptedCert.encryptedPrivKeyB64,
    sharedSecretHex,
    25
  );
  if (!privKeySucc) return false;

  //console.log("encryptedCert", encryptedCert);
  const certPacketSucc = await writeSplitSetupCmdPackages(
    setupCommands.Certificate,
    encryptedCert.encryptedCertB64,
    sharedSecretHex,
    25
  );
  if (!certPacketSucc) return false;

  // //console.log("encryptedPrivKey time");
  // const privKeySucc = true;
  // for (let i = 0; i < privKeyCmds.length; i++) {
  //   const succ = await writeBleCharacteristic(
  //     sharedSecret.sharedSecretHex,
  //     theAbf3,
  //     setupCommands["ServPrivKey"].cmdPre + formatHexToByte(i.toString(16)) + "," + privKeyCmds[i],
  //     setupCommands["ServPrivKey"].expectedReplyPre + formatHexToByte(i.toString(16)),
  //     setupCommands["ServPrivKey"].name
  //   );

  //   if (!succ) {
  //     console.error("failed to send priv key part", i);
  //     break;
  //   }
  // }
  // //console.log("temp done serv priv key");
  // return;

  const pModeSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    setupCommands["P-ModePIN"].cmdPre + pmodePin,
    setupCommands["P-ModePIN"].expectedReply,
    "P-ModePIN"
  );
  if (!pModeSucc) return false;

  const maintenanceSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    setupCommands.MaintenancePIN.cmdPre + maintenancePin,
    setupCommands.MaintenancePIN.expectedReply,
    "MaintenancePIN"
  );
  if (!maintenanceSucc) return false;

  const setupSucc = await writeBleCharacteristic(
    sharedSecretHex,
    theAbf3,
    setupCommands.SetupComplete.cmd,
    setupCommands.SetupComplete.expectedReply,
    setupCommands.SetupComplete.name
  );
  if (!setupSucc) return false;

  //console.log("setup complete");
  return true;
};

// util functions -----------------------------------------------------------------------

/**
 * Helper function for reading the value of a characteristic, and returning a hex string representation of the bytes.
 * @param characteristic
 */
const readBleCharacteristic = async (
  characteristic: BluetoothRemoteGATTCharacteristic,
  resultStart?: number,
  resultEnd?: number
): Promise<string> => {
  let data: DataView;

  try {
    data = await characteristic.readValue();
  } catch (e) {
    console.error("Couldn't read from characteristic", characteristic.uuid, e);
  }

  const hexStringArr: string[] = [];
  if (!resultStart) resultStart = 0; // including this index
  if (!resultEnd) resultEnd = data.byteLength; // not including this position
  for (let i = resultStart; i < resultEnd; i++) {
    let hexVal = data.getUint8(i).toString(16).toUpperCase();
    if (hexVal.length < 2) hexVal = `0${hexVal}`;
    hexStringArr.push(hexVal);
  }

  //console.log("read data from", characteristic.uuid, "value:", hexStringArr.join(""));

  return hexStringArr.join("");
};

/**
 * Wrapper function for writing an ascii string to the frog. If aesKey is given, automatically calls the
 * getEncrypted endpoint to encrypt the string for the frog, given the ecdh exchanged aes key. If
 * expectAbf4Reply is given, it waits for an abf4 notification reply and checks if it's what was expected
 * @param aesKey
 * @param characteristic
 * @param commandAscii
 * @param expectAbf4Reply
 * @param cmdName
 * @param expectedReplyIsPrefix
 * @returns
 */
const writeBleCharacteristic = async (
  aesKey: string,
  characteristic: BluetoothRemoteGATTCharacteristic,
  commandAscii: string,
  expectAbf4Reply?: string,
  cmdName?: string,
  expectedReplyIsPrefix?: boolean
): Promise<boolean> => {
  let hexValue = ""; // stores the hex representation of what's to be sent the frog

  // if the aesKey is given, encrypt the commandAscii message
  if (aesKey !== "") {
    try {
      hexValue = await getEncryptedMessage(commandAscii, aesKey);
    } catch (e) {
      console.error("couldn't get encrypted message");
      return false;
    }
  } else {
    hexValue = commandAscii; // assume that when no key give, commandAscii is the string to be sent
  }
  const hexArr: number[] = []; // to be used as the byte array, to be directly sent to the frog
  for (let i = 0; i < hexValue.length; i += 2) {
    hexArr.push(parseInt(hexValue.substring(i, i + 2), 16));
  }

  try {
    // if no expected reply, just send the command
    processingMessageSubject.next(`-- Sending command ${cmdName}...`);
    if (!expectAbf4Reply)
      try {
        await characteristic.writeValueWithResponse(Uint8Array.from(hexArr));
      } catch (e) {
        //console.warn(
        //   "gatt responded failure, but probably worked anyway",
        //   hexArr,
        //   "no reply command",
        //   expectAbf4Reply ?? ""
        // );
      }
    else {
      let reply = ""; // make a reference of teh reply

      const replySub = abf4Subject.subscribe((hexStr) => {
        reply = hexStr; // save the reply if the rxjs subject gets data
      });

      try {
        await characteristic.writeValueWithResponse(Uint8Array.from(hexArr));
      } catch (e) {
        //console.warn(
        //   "gatt responded failure, but probably worked anyway",
        //   expectAbf4Reply ? `expected reply ${expectAbf4Reply}` : "",
        //   cmdName ?? ""
        // );
      }

      // Wait until the subject gets data from the abf notification listener
      const startMillis = new Date().getTime();
      while (!reply && new Date().getTime() - startMillis < 5000) {
        // wait maximum 5 seconds for a reply
        // wait 5 seconds max
        await new Promise((resolve) => setTimeout(resolve, 200));
      }
      replySub.unsubscribe();

      if (!reply) {
        console.error("didn't receive reply to command", cmdName ?? "", hexValue, "aborting...");
        processingMessageSubject.next(`-- Didn't receive reply to command ${cmdName}, aborting...`);
        return false;
      }

      // Decrypt the frog's message
      const decryptedResponse = hexStrToAscii(await getDecryptedMessage(reply, aesKey));
      if (
        (!expectedReplyIsPrefix && expectAbf4Reply !== decryptedResponse) ||
        (expectedReplyIsPrefix && !decryptedResponse.includes(expectAbf4Reply))
      ) {
        console.error(
          "didn't receive correct reply to command, expectAbf4Reply",
          expectAbf4Reply ?? "",
          "aborting.."
        );
        processingMessageSubject.next(`-- Frog replied with unexpected value`);
        return false;
      }
      if (cmdName === "FrogUID") {
        frogUid = decryptedResponse.replace(expectAbf4Reply, "");
        //console.log("stored frogUID", frogUid);
        processingMessageSubject.next(`-- Got frogUID value ${frogUid}`);
      }
    }
  } catch (e) {
    console.error(
      "faield to write to characteristic",
      cmdName ?? "",
      characteristic.uuid,
      "value:",
      hexValue,
      e
    );

    return false;
  }
  //console.log("successfully wrote", cmdName ?? "", hexValue, "to", characteristic.uuid);

  return true;
};

const hexStrToAscii = (hex: string): string => {
  let res = "";
  let hexDebug = [];
  if (hex.length % 2 === 0) {
    for (let i = 0; i < hex.length; i += 2) {
      res += String.fromCharCode(parseInt(hex.substring(i, i + 2), 16));
      hexDebug.push(hex.substring(i, i + 2));
    }
    res = res.trim();
    //console.log("got hex to ascii:", res, hexDebug);
  }
  return res;
};

/**
 * Helper function to padd a 0 in front of a hex string number, so it represents a whole Byte.
 * @param hex
 * @returns
 */
const formatHexToByte = (hex: string): string => {
  if (hex.length < 2) hex = `0${hex}`;
  return hex.toUpperCase();
};

/**
 * Helper function for splitting a large setup command's payload, using the frog's dedicated packet splitting.
 * @param setupComand Object containing command information
 * @param totalPayload The setup command's payload (ascii string content after the index and comma)
 * @param aesKey The encryption key, if encryption is needed
 * @param numSplits The number of packets to be made
 * @returns
 */
const writeSplitSetupCmdPackages = async (
  setupComand: {
    name: string;
    cmdPre: string;
    expectedReplyPre: string;
  },
  totalPayload: string,
  aesKey: string,
  numSplits: number
): Promise<boolean> => {
  const certPacketLen = totalPayload.length / numSplits;
  for (let i = 0; i < numSplits; i++) {
    const splitPackSucc = await writeBleCharacteristic(
      aesKey,
      theAbf3,
      setupComand.cmdPre +
        `${formatHexToByte(i.toString(16))},${totalPayload.substring(
          i * certPacketLen,
          Math.min((i + 1) * certPacketLen, totalPayload.length)
        )}`,
      setupComand.expectedReplyPre + `${formatHexToByte(i.toString(16))}`,
      `${setupComand.name}${i}`
    );
    if (!splitPackSucc) return false;
  }
  const splitDoneSucc = await writeBleCharacteristic(
    aesKey,
    theAbf3,
    setupComand.cmdPre + "FF,",
    setupComand.expectedReplyPre + "FF",
    `splitPacketDone${setupComand.name}`
  );
  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (!splitDoneSucc) return false;

  return true;
};

const getFrogInfo = (): { serial: string; articleNumber: string } | undefined => {
  const fullFrogName = theBLEDevice?.name ?? "";
  const firstUnderscorePos = fullFrogName.indexOf("_");
  if (firstUnderscorePos < 0) {
    return undefined;
  }
  const theBase64ID = fullFrogName.substring(firstUnderscorePos + 1);
  if (!theBase64ID) {
    return undefined;
  }
  const information = base64ToHex(theBase64ID);
  //console.log("information:", information);
  if (!information) {
    return undefined;
  }
  if (information.length < 24) {
    return undefined;
  }
  const serialNum =
    switchByteSignificance(information.slice(12, 16)) +
    switchByteSignificance(information.slice(16, 20)); // characters 18 characters, starting from 12th
  let articleNum = parseInt(switchByteSignificance(information.slice(20, 24)), 16).toString();
  if (articleNum === "15549") articleNum = "99000"; // temporarily use other article number for prototoype
  return { serial: serialNum, articleNumber: articleNum };
};
const switchByteSignificance = (str: string): string => {
  if (!(str.length % 2 === 0)) {
    console.error("couldn't switch byte significance, input length wasn't multiple of 2");
    return str;
  }
  let out = "";
  for (let i = 0; i < str.length; i += 2) {
    out = str.charAt(i) + str.charAt(i + 1) + out;
  }
  return out;
};

function base64ToHex(str) {
  const raw = atob(str);
  let result = "";
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : "0" + hex;
  }
  return result.toUpperCase();
}

const getFrogNameFromUrl = (): string => {
  const urlStr = window.location.href;
  const searchParams = new URLSearchParams(new URL(urlStr)?.searchParams);
  const fullFrogName = searchParams?.get("id");
  if (!fullFrogName) {
    return "";
  }
  return fullFrogName;
};
