import React, { useEffect, useState } from "react";

import aesjs from "aes-js";

import logo from "./logo.svg";
import logout from "./assets/logout.png";
import "./App.css";
import { Subject } from "rxjs";
import { UI } from "./UI";
import { connect } from "./setup";
import keycloak from "./keycloak";
import { exchangeToken } from "./backend";

keycloak.init({ onLoad: "login-required" });

function App() {
  const [dashboardTokenAvailable, setDashboardTokenAvailable] = useState(""); // "" means not yet ready, no means account not available, yes means good
  useEffect(() => {
    keycloak.onAuthSuccess = () => {
      exchangeToken(keycloak?.token).then((res) => {
        if (res) {
          setDashboardTokenAvailable("yes");
        } else {
          setDashboardTokenAvailable("no");
        }
      });
    };
  }, []);

  useEffect(() => {
    console.log("logged in?", dashboardTokenAvailable);
  }, [dashboardTokenAvailable]);

  return (
    <div className="App">
      <div id="globalHeader" style={{ height: "3em", width: "100%", position: "relative" }}>
        <img
          src={logout}
          style={{
            height: "calc(100% - 15px)",
            right: "20px",
            display: "block",
            position: "absolute",
            top: "15px",

            cursor: "pointer",
          }}
          onClick={() => {
            console.log("clicked logout right?", keycloak);
            keycloak?.logout();
          }}
        />
      </div>
      <div
        id="mainBody"
        style={{
          height: "calc(100% - 3em)",
          maxHeight: "calc(100% - 3em)",
          overflowY: "hidden",
          width: "100%",
          position: "relative",
        }}
      >
        {dashboardTokenAvailable === "no" && (
          <div> Please create a PocketPilot account first and then retry</div>
        )}
        {dashboardTokenAvailable !== "" && <UI />}
        {/* <button
        style={{ display: "block" }}
        onClick={() => {
          connect();
        }}
      >
        Connect
      </button> */}
      </div>
    </div>
  );
}

export default App;
