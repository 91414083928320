import { Subject } from "rxjs";
import connectImg from "./assets/bluetooth_machine.png";
import { connect, handleSetup, theBLEDevice } from "./setup";
import { useEffect, useState } from "react";
import {
  CompanyDetails,
  LocationDetails,
  companies,
  getAvailableLocations,
  setAvailableCompanies,
} from "./backend";

export enum CurrentAppState {
  CONNECT,
  CM_NAME,
  COMPANY_LOCATION_INPUT,
  WIFI_INPUT,
  PINS_INPUT,
  PROCESSING, // calling endpoints, sending ble packets state
  DONE_SCREEN,
}

export const stateSubject = new Subject<CurrentAppState>();
export const processingMessageSubject = new Subject<string>();
const userInputSetupValues = {
  companyID: "",
  locationID: "",
  ssid: "",
  wifiPW: "",
  pmodePIN: "",
  maintenancePIN: "",
  nameInput: "",
};
let storedSharedSecret = "";
let setupSucessFlag = false;
let setupLog = ""; // temporary place to hold log
let setupErrormsg = "";

// The UI TSX Components -------------------------------------------------------------------

export const UI = () => {
  const [currentState, setCurrentState] = useState(CurrentAppState.CONNECT);

  useEffect(() => {
    const stateSubscription = stateSubject.subscribe((state) => {
      setCurrentState(state);
    });

    return function cleanup() {
      stateSubscription?.unsubscribe();
    };
  }, []);

  return (
    <>
      {currentState === CurrentAppState.CONNECT && <ConnectScreen />}
      {currentState === CurrentAppState.CM_NAME && <NameScreen />}
      {currentState === CurrentAppState.COMPANY_LOCATION_INPUT && <CompanyLocationScreen />}
      {currentState === CurrentAppState.WIFI_INPUT && <WifiScreen />}
      {currentState === CurrentAppState.PINS_INPUT && <PinsScreen />}
      {currentState === CurrentAppState.PROCESSING && <ProcessingScreen />}
      {currentState === CurrentAppState.DONE_SCREEN && <DoneScreen />}
    </>
  );
};

const ConnectScreen = () => {
  const [blink, setBlink] = useState(false);
  return (
    <>
      <div className="fullSize">
        <h2 className="titleHeader">PocketPilot 3 Test Setup</h2>

        <img
          className={blink ? "blink" : ""}
          style={{
            display: "block",
            position: "relative",
            top: "calc(30% - 2em)",
            height: "30%",
            margin: "0 auto",
            border: "solid 2px darkgrey",
            borderRadius: "15px",
            cursor: "pointer",
          }}
          src={connectImg}
          onClick={() => {
            setBlink(true);
            console.log("set blink");
            connect()
              .then((sharedSecret) => {
                return sharedSecret;
              })
              .then((sharedSecret) => {
                setBlink(false);
                if (sharedSecret) {
                  storedSharedSecret = sharedSecret.sharedSecretHex;
                  stateSubject.next(CurrentAppState.CM_NAME);
                }
              });
          }}
        />
      </div>
    </>
  );
};

const WifiScreen = () => {
  const [ssid, setSSID] = useState(userInputSetupValues.ssid);
  const [wifiPW, setWifiPW] = useState(userInputSetupValues.wifiPW);

  return (
    <>
      <div className="fullSize">
        <span className="inputPageHeader">Please submit your WiFi credentials</span>
        <div className="inputBoxArea">
          <div className="verticalCenteredContainer">
            <form
              className="pure-form pure-form-stacked"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                {/* SSID input */}
                <label htmlFor="ssidInput">
                  SSID
                  <input
                    type="text"
                    id="ssidInput"
                    value={ssid}
                    onChange={(e) => {
                      setSSID(e.target.value);
                      userInputSetupValues.ssid = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            </form>
            <form
              className="pure-form pure-form-stacked"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                {/* Password input */}
                <label htmlFor="passwordInput">
                  Password
                  <input
                    type="password"
                    id="passwordInput"
                    value={wifiPW}
                    onChange={(e) => {
                      setWifiPW(e.target.value);
                      userInputSetupValues.wifiPW = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            </form>
          </div>
        </div>

        <BackNextBar
          callback={(pressed) => {
            if (pressed === BottomBar.BACK) {
              stateSubject.next(CurrentAppState.COMPANY_LOCATION_INPUT);
            } else {
              stateSubject.next(CurrentAppState.PINS_INPUT);
            }
          }}
          nextAvailable={ssid !== "" && wifiPW !== ""}
        />
      </div>
    </>
  );
};

const NameScreen = () => {
  const [nameInput, setNameInput] = useState(userInputSetupValues.nameInput);
  return (
    <>
      <div className="fullSize">
        <span className="inputPageHeader">Please choose a name</span>
        <div className="inputBoxArea">
          <div className="verticalCenteredContainer">
            <form
              className="pure-form pure-form-stacked"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                {/* SSID input */}
                <label htmlFor="nameInput">
                  Name
                  <input
                    type="text"
                    id="nameInput"
                    value={nameInput}
                    onChange={(e) => {
                      // nameChangeHandler(e, 10); // name is only used in backend at the moment, no restrictions
                      userInputSetupValues.nameInput = e.target.value;
                      setNameInput(e.target.value);
                    }}
                  />
                </label>
              </fieldset>
            </form>
          </div>
        </div>

        <BackNextBar
          callback={(pressed) => {
            if (pressed === BottomBar.BACK) {
              stateSubject.next(CurrentAppState.CONNECT);
              theBLEDevice?.gatt?.disconnect();
            } else {
              stateSubject.next(CurrentAppState.COMPANY_LOCATION_INPUT);
              console.log("chosen name:", nameInput, userInputSetupValues.nameInput);
            }
          }}
          nextAvailable={nameInput?.length !== 0}
        />
      </div>
    </>
  );
};

const PinsScreen = () => {
  const [pmodePin, setPmodePin] = useState(userInputSetupValues.pmodePIN);
  const [maintenancePin, setMaintenancePin] = useState(userInputSetupValues.maintenancePIN);
  return (
    <>
      <div className="fullSize">
        <span className="inputPageHeader">Please submit your PIN codes</span>
        <div className="inputBoxArea">
          <div className="verticalCenteredContainer">
            <form
              className="pure-form pure-form-stacked"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                {/* SSID input */}
                <label htmlFor="pmodeInput">
                  P-Mode
                  <input
                    type="number"
                    id="pmodeInput"
                    value={pmodePin}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const cleanedValue = machinePinInputFilter(inputValue);
                      setPmodePin(cleanedValue);
                      userInputSetupValues.pmodePIN = cleanedValue;
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete={"off"}
                    autoCorrect={"off"}
                    autoCapitalize={"off"}
                  />
                </label>
              </fieldset>
            </form>
            <form
              className="pure-form pure-form-stacked"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                {/* Password input */}
                <label htmlFor="maintenanceInput">
                  Maintenance
                  <input
                    type="number"
                    id="maintenanceInput"
                    value={maintenancePin}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const cleanedValue = machinePinInputFilter(inputValue);
                      setMaintenancePin(cleanedValue);
                      userInputSetupValues.maintenancePIN = cleanedValue;
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete={"off"}
                    autoCorrect={"off"}
                    autoCapitalize={"off"}
                  />
                </label>
              </fieldset>
            </form>
          </div>
        </div>

        <BackNextBar
          callback={(pressed) => {
            if (pressed === BottomBar.BACK) {
              stateSubject.next(CurrentAppState.WIFI_INPUT);
            } else {
              stateSubject.next(CurrentAppState.PROCESSING);
              // also start setup now
              console.log("called handle Setup");
              handleSetup(
                storedSharedSecret,
                userInputSetupValues.companyID,
                userInputSetupValues.locationID,
                userInputSetupValues.ssid,
                userInputSetupValues.wifiPW,
                userInputSetupValues.pmodePIN,
                userInputSetupValues.maintenancePIN,
                userInputSetupValues.nameInput
              ).then((succ) => {
                setupSucessFlag = succ;
                if (!succ) {
                  setupErrormsg = setupLog.split("\n")[setupLog.split("\n").length - 2];
                }
                stateSubject.next(CurrentAppState.DONE_SCREEN);
              });
            }
          }}
          nextAvailable={pmodePin?.length === 6 && maintenancePin?.length === 6}
        />
      </div>
    </>
  );
};

const CompanyLocationScreen = () => {
  const [companiesSet, setCompaniesSet] = useState<CompanyDetails[] | undefined>();
  const [chosenCompany, setChosenCompany] = useState<CompanyDetails | undefined>();
  const [availableLocations, setAvailableLocations] = useState<LocationDetails[] | undefined>();
  const [chosenLocation, setChosenLocation] = useState<LocationDetails | undefined>();

  useEffect(() => {
    setAvailableCompanies().then((succ) => {
      if (!succ) {
        console.error("couldn't get the user companies, do something about it?");
      } else {
        // show the available companies, choose from them
        setCompaniesSet(companies);
      }
    });
  }, []);

  useEffect(() => {
    if (chosenCompany) {
      console.log("chosen company", chosenCompany);
      userInputSetupValues.companyID = chosenCompany.id;
      getAvailableLocations(chosenCompany.id).then((locations) => {
        setAvailableLocations(locations);
      });

      // reset chosen location
      setChosenLocation(undefined);
    }
  }, [chosenCompany]);

  useEffect(() => {
    if (chosenLocation) {
      console.log("location chosen:", chosenLocation);
      userInputSetupValues.locationID = chosenLocation.id;
    }
  }, [chosenLocation]);

  return (
    <>
      <div className="fullSize" style={{ height: "calc(100% - 3em)" }}>
        <div className="inputPageHeader">
          Please select your the company and location from your account:
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            top: "6em",
          }}
        >
          <div style={{ width: "fit-content", marginBottom: "1em" }}>Select Company</div>

          {companiesSet?.map((v, i) => {
            return (
              <button
                key={`company_choice_${i}`}
                className={
                  "button-large pure-button" +
                  (chosenCompany?.id === v.id ? " activeButton" : " inactiveButton")
                }
                style={{
                  display: "block",
                  marginBottom: "0.5em",
                  width: "15em",
                }}
                onClick={() => {
                  setChosenCompany(v);
                }}
              >
                {v.name}
              </button>
            );
          })}
          {chosenCompany && (
            <>
              <div style={{ width: "fit-content", marginBottom: "1em", marginTop: "1em" }}>
                Select Location
              </div>
              {availableLocations?.map((v, i) => {
                return (
                  <button
                    key={`company_choice_${i}`}
                    className={
                      "button-large pure-button" +
                      (chosenLocation?.id === v.id ? " activeButton" : " inactiveButton")
                    }
                    style={{
                      display: "block",
                      marginBottom: "0.5em",
                      width: "15em",
                    }}
                    onClick={() => {
                      setChosenLocation(v);
                    }}
                  >
                    {v.name}
                  </button>
                );
              })}
            </>
          )}
        </div>
      </div>
      <BackNextBar
        callback={(pressed) => {
          if (pressed === BottomBar.BACK) {
            stateSubject.next(CurrentAppState.CM_NAME);
          } else {
            stateSubject.next(CurrentAppState.WIFI_INPUT);
          }
        }}
        nextAvailable={chosenLocation !== undefined}
      />
    </>
  );
};

const ProcessingScreen = (props: {}) => {
  const [log, setLog] = useState("");

  useEffect(() => {
    const logSub = processingMessageSubject.subscribe((msg) => {
      setupLog += `${msg}\n`;
      setLog(setupLog);
      // setupErrormsg = msg; // in case it failed, store here the last log message
      try {
        document.getElementById("logDiv").scrollTop =
          document.getElementById("logDiv").scrollHeight;
      } catch (e) {
        console.log(e);
      }
    });

    return function cleanup() {
      logSub?.unsubscribe();
    };
  }, []);

  return (
    <div className="fullSize">
      <div style={{ height: "5em", marginTop: "2em" }}>
        <div className="verticalCenteredContainer">Processing...</div>
      </div>

      <div style={{ height: "calc(100% - 7em)", width: "95%", margin: "0 auto" }}>
        <div
          id="logDiv"
          className="verticalCenteredContainer"
          style={{
            color: "orange",
            whiteSpace: "pre",
            lineHeight: "1.5em",
            overflowY: "auto",
            alignItems: "start",
          }}
        >
          {log}
        </div>
      </div>
    </div>
  );
};

const DoneScreen = (props: {}) => {
  useEffect(() => {
    const doneLogDiv = document.getElementById("doneLog");
    if (doneLogDiv) {
      doneLogDiv.scrollTop = doneLogDiv.scrollHeight;
      console.log("should've scrolled down");
    }
  }, []);
  return (
    <>
      <div className="fullSize">
        {setupSucessFlag && (
          <div className="verticalCenteredContainer" style={{ color: "green" }}>
            Success
          </div>
        )}
        {!setupSucessFlag && (
          // <div className="verticalCenteredContainer" style={{ color: "red", whiteSpace: "pre" }}>
          //   {`Failed Setup!\n\nReason: ${setupErrormsg}
          //   `}
          // </div>
          <>
            <div
              id="doneLog"
              className="verticalCenteredContainer"
              style={{
                color: "orange",
                whiteSpace: "pre",
                lineHeight: "1.5em",
                overflowY: "auto",
                alignItems: "start",
                width: "90%",
                margin: "0 auto",
              }}
            >
              <div style={{ color: "red", width: "fit-content" }}>
                {`Failed Setup!\n\nReason: ${setupErrormsg}
             `}
              </div>
              {setupLog}
            </div>
          </>
        )}
      </div>
    </>
  );
};

enum BottomBar {
  BACK,
  NEXT,
}
const BackNextBar = (props: {
  callback?: (pressed: BottomBar) => void;
  nextAvailable?: boolean;
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "3em",
        position: "absolute",
        top: "calc(100% - 3em)",
        backgroundColor: "#191919",
      }}
    >
      <div className="horizontalCenteredContainer" style={{ gap: "1em" }}>
        <button
          className="pure-button"
          style={{ width: "35%" }}
          onClick={() => {
            if (props.callback) props.callback(BottomBar.BACK);
          }}
        >
          back
        </button>
        <button
          className={"pure-button" + (!props.nextAvailable ? " inactiveButton" : "")}
          style={{ width: "35%" }}
          onClick={() => {
            if (props.callback && props.nextAvailable) props.callback(BottomBar.NEXT);
          }}
        >
          next
        </button>
      </div>
    </div>
  );
};

// UI Utils --------------------------------------------------------------------------------------------

export const machinePinInputFilter = (inputValue: string): string => {
  let cleanedValue = "";
  for (let i = 0; i < inputValue.length; i++) {
    switch (inputValue.charAt(i)) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        cleanedValue += inputValue.charAt(i);
        break;

      default:
      // ignore value
    }
  }
  cleanedValue = cleanedValue.substring(0, 6);
  return cleanedValue;
};

const nameChangeHandler = (e: any, maxLen: number) => {
  const givenInput = e.target.value.toString();
  e.target.value = (givenInput as string).replace(/[^a-zA-Z0-9]/g, "");
  e.target.value = (e.target.value as string).replaceAll(" ", "");
  if (givenInput.length > maxLen) {
    e.target.value = givenInput.substring(0, Math.min(maxLen, givenInput.length));
  }
};
